// Moderators

#admin-moderators {
	padding: 20px;
	.btn-clean {
		margin-top: 30px;
	}
	input {
		background-color: $white !important;
		border-radius: 10px !important;
	}
}

.modal-create-moderator {
	@include modal();
	.modal-dialog {
		max-width: 700px;
	}
	.modal-header {
		display: none;
	}
}

#modal-create-moderator {
	padding: 20px;
	input, select {
		background-color: $white !important;
		border-radius: 10px !important;
	}
	button[type="submit"] {
		background-color: $yellow !important;
		border-radius: 10px !important;
		color: $black !important;
		text-transform: uppercase;
		font-family: $font-family-bold;
		font-size: 25px;
		width: 100%;
	}
	button[type="button"] {
		background-color: $black !important;
		border-radius: 10px !important;
		color: $white !important;
		text-transform: uppercase;
		font-family: $font-family-bold;
		font-size: 25px;
		width: 100%;
	}
	table {
		width: 100%;
		margin-bottom: 15px;
		td, th {
			width: 20%;
			padding-top: 5px;
			padding-bottom: 5px;
			&:first-child {
				width: 40%;
			}
			p {
				margin: 0px;
				margin-left: 5px;
			}
		}
		th {
			font-weight: normal;
			font-family: $font-family-bold;
		}
	}
	.td-check {
		display: flex;
		font-size: 14px;
		.checkbox-table-component {
			margin-right: 5px;
		}
	}
	.td-center {
		.checkbox-table-component {
			margin: 0 auto;
		}
	}
	h3 {
		font-family: $font-family-bold;
		color: $blue;
		font-size: 20px;
		margin-top: 15px;
		padding-left: 10px;
		padding-right: 10px;
	}
	.container-icon {
		height: 42px;
		overflow: hidden;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: $white;
		border-radius: 8px;
		border-top-right-radius: 0px;
		border-bottom-right-radius: 0px;
		border-right: 0px;
		padding-left: 8px;
		padding-right: 8px;
		img {
			width: 22px;
			height: 22px;
			object-fit: contain;
		}
	}
	.container-select {
		p.label {
			color: lighten($black,20);
			font-family: $font-family-bold;
			font-size: 14px;
			margin: 0px;
			margin-bottom: 5px;
		}
		& > div {
			display: flex;
			background-color: $white !important;
			border: 1px solid darken($gray,20) !important;
			border-radius: 8px !important;
			margin-bottom: 1rem;
			align-items: center;
			select {
				width: 80px;
				height: 42px !important;
				background-color: $white !important;
				border-radius: 8px !important;
				border-top-right-radius: 0px !important;
				border-bottom-right-radius: 0px !important;
				border: 0px !important;
				&.document_type {
					width: 60px;
				}
			}
			.form-group-input {
				flex: 1;
				input {
					border-top-left-radius: 0px !important;
					border-bottom-left-radius: 0px !important;
					border: 0px !important;
				}
			}
			.form-group {
				margin-bottom: 0px;
			}
		}
	}
}