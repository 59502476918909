// Products

#admin-products {
	padding: 20px;
	.btn-clean {
		margin-top: 30px;
	}
	input {
		background-color: $white !important;
		border-radius: 10px !important;
	}
}

.modal-create-product {
	@include modal();
	.modal-dialog {
		max-width: 700px;
	}
	.modal-header {
		display: none;
	}
}

#modal-create-moderator {
	padding: 20px;
	.paperclip-image {
		button {
			height: auto;
			width: auto;
			margin-bottom: 20px;
			padding: 0px;
			min-width: 0px;
			box-shadow: none !important;
			border: 0px !important;
			background-color: transparent !important;
		}
	}
	.selected-image {
		border-radius: 65px;
		width: 130px;
		height: 130px;
		object-fit: cover;
	}
	input, select, textarea {
		background-color: $white !important;
		border-radius: 10px !important;
		box-shadow: none !important;
		border: 1px solid darken($gray,20) !important;
	}
	.container-icon-paperclip {
		display: none;
	}
	h3 {
		font-family: $font-family-bold;
		color: $blue;
		font-size: 20px;
		margin-top: 15px;
		padding-left: 0px;
		padding-right: 10px;
		margin-bottom: 10px;
	}
	button[type="submit"] {
		background-color: $yellow !important;
		border-radius: 10px !important;
		color: $black !important;
		text-transform: uppercase;
		font-family: $font-family-bold;
		font-size: 25px;
		width: 100%;
	}
	button.btn-close {
		background-color: $black !important;
		border-radius: 10px !important;
		color: $white !important;
		text-transform: uppercase;
		font-family: $font-family-bold;
		font-size: 25px;
		width: 100%;
	}
	.unit-name {
		font-family: $font-family-bold;
		margin-top: 8px;
	}
	.row-buttons {
		margin-top: 10px;
	}
}