// Transformers

#admin-transformers {
	padding: 20px;
	.btn-clean {
		margin-top: 30px;
	}
	input {
		background-color: $white !important;
		border-radius: 10px !important;
	}
}

.modal-create-transformer {
	.conversion {
		text-align: right;
		font-size: 12px;
		margin: 0px;
	}
	.area-container {
		margin-bottom: 5px;
	}
}