@font-face {
    font-family: $font-family;
    src: url($fonts-path + $font-family + ".ttf");
}

@font-face {
    font-family: $font-family-bold;
    src: url($fonts-path + $font-family-bold + ".ttf");
}

@font-face {
    font-family: $font-family-black;
    src: url($fonts-path + $font-family-black + ".ttf");
}

@font-face {
    font-family: $font-family-medium;
    src: url($fonts-path + $font-family-medium + ".ttf");
}