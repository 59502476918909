// Login

#login {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
	.login-top {
		position: absolute;
		top: 0px;
		right: 0px;
		z-index: 0;
	}
	.login-bottom {
		position: absolute;
		bottom: 0px;
		left: 0px;
		z-index: 0;
	}
	.container-icon {
		font-size: 50px;
		width: 100px;
		height: 100px;
		background-color: $prod-green;
		display: flex;
		justify-content: center;
		align-items: center;
		color: $white;
		border-radius: 200px;
		position: relative;
		top: -70px;
		margin: 0 auto;
		margin-bottom: -50px;
	}
	.recover, .register {
		margin-top: 10px;
		margin-bottom: 25px;
		font-size: 18px;
		a {
			font-family: $font-family-bold;
			color: $blue !important;
		}
	}
	.register {
		text-align: right;
		@media (max-width: 991px) {
			text-align: left;
		}
	}
	.arrow-green {
		position: absolute;
		left: -100px;
		top: 20px;
		width: 500px;
		object-fit: contain;
		z-index: 0;
	}
	form {
		background-color: $white;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		max-width: 550px;
		width: 90%;
		@include shadow2();
		border-radius: 10px;
		padding: 0px;
		padding-right: 10px;
		padding-left: 10px;
		position: relative;
		padding-top: 70px;
		@media (max-width: 600px) {
			padding-right: 0px;
			padding-left: 0px;
		}
		.container-logo {
			padding: 20px;
			padding-top: 0px;
			text-align: center;
			img {
				width: 100%;
				max-width: 250px;
				margin: 0 auto;
			}
		}
		.input-group {
			label {
				color: $prod-gray;
				font-size: 14px;
			}
			.input-group-prepend {
				img {
					width: 30px;
					height: 30px;
					object-fit: contain;
					margin-top: 5px;
				}
			}
			input {
				border: 0px !important;
				font-size: 14px;
				border-radius: 0px !important;
				background-color: $gray !important;
			}
			.form-group {
				margin: 0px;
				flex: 1;
			}
			display: flex;
			background-color: $gray;
			border-radius: 10px;
			margin-bottom: 15px;
			padding: 5px;
			border: 1px solid darken($gray,20);
		}
		.container-input {
			padding: 20px;
			padding-left: 30px;
			padding-right: 30px;
			width: 95%;
			border-radius: 10px;
			margin: 0 auto;
			margin-top: 20px;
		}
		input {
			font-size: 13px;
		}
		button {
			border-radius: 10px !important;
			max-width: 300px;
			width: 100%;
			font-size: 18px;
			margin: 0 auto;
			margin-top: 10px;
			color: $black !important;
			background-color: $yellow !important;
			padding-top: 10px !important;
			padding-bottom: 10px !important;
			font-family: $font-family-bold;
			padding-top: 15px !important;
			padding-bottom: 15px !important;
		}
	}
}