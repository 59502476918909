// Title

.container-title-component {
	padding: 10px;
	.title-component {
		width: 100%;
		font-size: 22px;
		font-family: $font-family-bold;
		color: $blue;
		margin: 0px;
		padding: 10px;
	}
}