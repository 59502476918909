// Glass Types

#admin-glass-types {
	padding: 20px;
	.btn-clean {
		margin-top: 30px;
	}
	input {
		background-color: $white !important;
		border-radius: 10px !important;
	}
}