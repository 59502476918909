// Revenue

#admin-revenue {
	padding: 20px;
	.container-form {
		background-color: $white;
		@include shadow2();
		border-radius: 10px;
		padding: 20px;
		max-width: 700px;
		margin: 0 auto;
	}
	h4 {
		margin: 0px;
		color: $blue;
		font-weight: bold;
		margin-bottom: 20px;
	}
	button[type="button"] {
		background-color: $yellow !important;
		border-radius: 10px !important;
		color: $black !important;
		text-transform: uppercase;
		font-family: $font-family-bold;
		font-size: 25px;
		width: 200px;
	}
	label {
		font-size: 16px;
	}
	select {
		border-radius: 10px !important;
	}
	.input-decimal {
		margin-bottom: 25px;
		label {
			text-align: center;
		}
		input {
			border-top: 0px !important;
			border-left: 0px !important;
			border-right: 0px !important;
			border-bottom: 2px solid $green !important;
			border-radius: 0px !important;
			text-align: center;
			font-size: 30px;
		}			
	}
}