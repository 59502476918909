// Menu

:root {
  --landscape-width: 22;
  --portrait-width: 100%;
  --header-height: 35px;
  --secs: 0.6s;
  --bg-left: springgreen;
  --bg-right: cyan;
  --bg-main: lemonchiffon;
}
#layout {
  display: flex;
  overflow: hidden;

  .header {
    position: relative;
    height: var(--header-height);
    text-align: center;
    .title { position: absolute; left: 0; right: 0; }
  }
  .content {
    overflow: auto;
    box-sizing: border-box;
    height: calc(100vh - var(--header-height));
    padding: 1rem;
  }

  .container-router {
    position: absolute;
    width: 100%;
    transition: var(--secs);
    &.open {
      @media (min-width: 768px) {
        width: calc(100% - 305px);
        margin-left: $width-menu + 20px;
      }
    }
  }
}

#left, #right { 
  transition: width var(--secs); 
  width: 0;
  @media (orientation: landscape) {
    &.open { width: calc(1% * var(--landscape-width)); }
  }
  .icon { position: fixed; z-index: 10;
    width: var(--header-height); height: var(--header-height);
    line-height: var(--header-height); font-size: var(--header-height);
    text-align: center; user-select: none; cursor: pointer;
    color: white;
    border-radius: 50%;
  }
  .sidebar { transition: transform var(--secs);
    @media (orientation: portrait) { width: var(--portrait-width); }
    @media (orientation: landscape) { width: calc(1vw * var(--landscape-width)); }
    .header { width: calc(100% - var(--header-height)); }
  }
}

#left { 
  z-index: 5;
  .sidebar {
    display: flex;
    width: 100vw;
    @media (max-width: 767px) {
      width: 100vw;
    }
    @media (max-width: 767px) {
      &:not(.open-responsive) {
        .container-menu {
          padding-left: 80px;
        }
        .container-router {
          width: calc(100% + 185px);
        }
        .menu {
          left: -300px !important;
          .nav-link {
            flex-direction: row-reverse;
            display: flex;
            &-icon {
              margin-right: 19px;
            }
            &-text {
              display: none;
            }
          }
        }
      }      
    }

    &.closed { 
      .container-menu {
        padding-left: 50px;
      }
      .container-router {
        width: calc(100% + 185px);
        margin-left: 20px;
      }
      .menu {
        left: -230px;
        .nav-link {
          flex-direction: row-reverse;
          display: flex;
          &-icon {
            margin-right: 19px;
          }
          &-text {
            display: none;
          }
        }
      }      
    }
  }
}

.shadow-close {
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 999;
  left: -100%;
  @media (min-width: 768px) {
    display: none;
  }
  &.open {
    left: 0;
  }
}

.container-scroll {
  overflow-y: auto;
  height: 100vh;
  @include scroll();
  .navbar-toggler-menu {
    margin-left: 8px;
    margin-bottom: 20px;
    transition: var(--secs);
    margin-left: 10px;
    &.responsive {
      display: none;
    }
    @media (max-width: 991px) {
      &.responsive {
        display: inline-block;
      }
      &:not(.responsive) {
        display: none;
      }
    }
    &.closed {
      padding-left: 80%;
    }
    i.fa {
      color: $white;
    }
  }
}