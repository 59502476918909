// Variables

$gray: #f2f2f2;
$blue: #0c1260;
$black: #000;
$black2: #222;
$white: #fff;
$input: #f1f1f1;
$active: #0d1214;
$danger: #d20b06;
$green: #136f40;
$green_light: #88c440;
$orange: #ef6526;
$light: #F5F5F5;
$yellow: #fdb910;
$black2: #1a1a1c;
$default: #75757d;
$default2: #48484a;
$gray2: #969aab;

$prod-green: #779a0a;
$prod-green2: #099896;
$prod-green3: #53b7b5;
$prod-gray: #606060;
$prod-gray2: #eaeaea;
$prod-gray3: #f2f2f2;
$prod-blue1: #3654e8;
$prod-blue2: #009bdb;
$prod-light: #5c5b60;
$prod-orange: #daa509;
$prod-black: #000;
$prod-purple: #2a4055;
$prod-table-green: #fafbfd;
$prod-menu: #e8e8e8;

$fonts-path: "../../assets/fonts/";
$width-menu: 300px;
$header-height: 127px;
$footer-height: 32px;

$font-family: "Roboto-Regular";
$font-family-bold: "Roboto-Bold";
$font-family-black: "Roboto-Black";
$font-family-medium: "Roboto-Medium";

$height-banner: 90px;
$height-item: 37px;

$total_1: #22cd68;
$total_2: #10767a;
$total_3: #1a89e5;
$red_td: #f28c8d;

$_item_reporte1: #d20b06;
$_item_reporte2: #d1ad1b;
$_item_reporte3: #1bd156;
$_item_reporte4: #111cd0;

$card-blue: #1379c4;
$card-cyan: #adfbff;
$card-pink: #d48cfa;
$card-gray: #eff4f0;

$submenu-gray: #3e4756;