// Home

#admin-home {
	padding: 20px;
	.revenue {
		text-align: center;
		color: $blue;
		margin-top: 20px;
		font-size: 30px;
		font-family: $font-family-bold;
	}
	.container-card {
		padding: 15px;
		background-color: $white;
		margin-bottom: 20px;
		@include shadow2();
		border-radius: 10px;
		.view-more {
			color: $green !important;
			img {
				@include recolor($green);
			}
		}
		h3 {
			color: $green;
			font-size: 20px;
			font-weight: bold;
		}
		h4 {
			color: $black;
			font-size: 16px;
			font-weight: bold;
		}
		&.blue {
			background-color: $card-blue;
			h3 {
				color: $white;
			}
			th, td {
				color: $white;
			}
			.view-more {
				color: $white !important;
				img {
					@include recolor($white);
				}
			}
		}
		&.cyan {
			background-color: $card-cyan;
		}
		&.pink {
			background-color: $card-pink;
			h3 {
				color: $white;
			}
			th, td {
				color: $white;
			}
			.view-more {
				color: $black !important;
				img {
					@include recolor($black);
				}
			}
		}
		table {
			margin-top: 15px;
			th, td {
				font-size: 14px;
				padding: 5px;
			}
			th {
				border-top: 0px !important;
			}
		}
		h5 {
			font-size: 14px;
			margin-bottom: 5px;
			margin-top: 15px;
		}
		.date {
			color: $card-blue;
			font-weight: bold;
			font-size: 27px;
		}
	}
	.container-plans {
		margin-bottom: 25px;
		h4 {
			margin: 0px;
			color: darken($card-blue,20);
			font-weight: bold;
			margin-bottom: 20px;
			margin-top: 20px;
		}
		.item-plan {
			border-radius: 10px;
			display: flex;
			flex: 1;
			margin-right: 10px;
			margin-left: 10px;
			overflow: hidden;
			margin-bottom: 15px;
			@include shadow2();
			background-color: $white;
			.container-image {
				padding: 10px;
				background-color: $gray;
				img {
					width: 50px;
					height: 50px;
					object-fit: contain;
				}
			}
			p {
				margin: 0px;
				padding-left: 10px;
				padding-right: 10px;
				font-size: 16px;
				&.bold {
					font-family: $font-family-bold;
					font-size: 20px;
				}
			}
			.container-data {
				display: flex;
				flex-direction: column;
				justify-content: center;
			}
			&.active {
				border-color: $green !important;
				.container-image {
					background-color: $green;
					img {
						@include recolor($white);
					}
				}
			}
		}
	}
	.view-more {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		font-weight: bold;
		text-decoration: none !important;
		img {
			width: 15px;
			height: 15px;
			object-fit: contain;
			margin-left: 5px;
		}
	}
}