// Profile

#profile {
	label {
		font-size: 14px;
		font-family: $font-family-bold;
	}
	input, select {
		border: 0px !important;
		border-radius: 10px !important;
		background-color: $gray !important;
	}
	h3 {
		font-size: 14px;
		font-family: $font-family-bold;
		margin-bottom: 20px;
		color: $black;
	}
	.container-avatar {
		text-align: center;
		margin-bottom: 20px;
		h4 {
			font-size: 24px;
			font-family: $font-family-bold;
		}
		h5 {
			font-size: 20px;
		}
	}
	.schedule-title {
		font-family: $font-family-bold;
		font-size: 16px;
		margin-bottom: 0px;
	}
	.item-profile {
		display: flex;
		border-top: 1px solid darken($gray,10);
		padding: 10px;
		padding-left: 15px;
		padding-right: 15px;
		align-items: center;
		img {
			width: 30px;
			height: 30px;
			object-fit: contain;
			margin-right: 10px;
		}
		p {
			padding-left: 10px;
			margin: 0px;
			font-size: 14px;
		}
	}
	.container-tabs {
		display: flex;
		margin-bottom: 10px;
		@media (max-width: 600px) {
			display: block;
		}
		.tab {
			cursor: pointer;
			border-bottom: 10px solid $gray;
			padding: 10px;
			padding-left: 18px;
			padding-right: 18px;
			color: darken($gray,20);
			@media (max-width: 600px) {
				text-align: center;
				margin-bottom: 10px;
			}
			&.active {
				color: $black;
				border-bottom: 10px solid $black;
			}
		}
		.tab-flex {
			border-bottom: 10px solid $gray;
			flex: 1;
			@media (max-width: 600px) {
				display: none;
			}
		}
	}
	.select-document {
		p {
			font-family: $font-family-bold;
			margin-bottom: 10px;
			font-size: 14px;
		}
		.container-select-document {
			display: flex;
			select {
				width: 120px;
				margin-right: 10px;
			}
		}
	}
	.container-form {
		border-radius: 10px;
		padding: 30px;
		margin: 0px;
		background-color: $white;
		border-radius: 10px;
		width: 100%;
		margin: 0 auto;
		margin-top: 20px;
		margin-bottom: 20px;
		@include shadow3();
		button[type="submit"] {
			margin-top: 20px;
		}
	}
	.container-data {
		border-radius: 10px;
		background-color: $white;
		@include shadow3();
		width: 100%;
		margin: 0 auto;
		margin-top: 20px;
		margin-bottom: 20px;
	}
	background-color: $gray;
	form {
		padding: 30px;
		padding-top: 10px;
		@media (max-width: 600px) {
			padding: 0px;
		}
		button {
			border-radius: 10px !important;
			width: 200px;
			font-size: 20px;
			margin: 0 auto;
			margin-top: 10px;
			color: $black !important;
			background-color: $yellow !important;
			padding-top: 10px !important;
			padding-bottom: 10px !important;
			font-family: $font-family-bold;
		}
		.form-group {
			input, select {
				border: 1px solid darken($gray,20) !important;
				background-color: lighten($gray,3) !important;
			}
			.speciality_select {
				border-radius: 10px !important;
			}
		}
		label {
			color: lighten($black,20);
			font-family: $font-family-bold;
			font-size: 14px;
			margin-top: 10px;
			margin-bottom: 0px !important;
		}
		h3 {
			font-size: 18px;
			color: $prod-green;
			font-family: $font-family-bold;
			margin-bottom: 20px;
		}
		h4 {
			color: lighten($black,20);
			font-size: 20px;
			margin-top: 15px;
		}
		p {
			font-size: 16px;
			color: lighten($black,20);
		}
	}
	.container-icon {
		height: 42px;
		overflow: hidden;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: lighten($gray,3);
		border-radius: 8px;
		border-top-right-radius: 0px;
		border-bottom-right-radius: 0px;
		border-right: 0px;
		padding-left: 8px;
		padding-right: 8px;
		img {
			width: 22px;
			height: 22px;
			object-fit: contain;
		}
	}
	.container-select {
		p.label {
			color: lighten($black,20);
			font-family: $font-family-bold;
			font-size: 14px;
			margin: 0px;
			margin-bottom: 5px;
			margin-top: 10px;
		}
		& > div {
			display: flex;
			background-color: lighten($gray,3) !important;
			border: 1px solid darken($gray,20) !important;
			border-radius: 8px !important;
			margin-bottom: 1rem;
			align-items: center;
			select {
				width: 80px;
				height: 42px !important;
				background-color: lighten($gray,3) !important;
				border-radius: 8px !important;
				border-top-right-radius: 0px !important;
				border-bottom-right-radius: 0px !important;
				border: 0px !important;
				&.document_type {
					width: 60px;
				}
			}
			.form-group-input {
				flex: 1;
				input {
					border-top-left-radius: 0px !important;
					border-bottom-left-radius: 0px !important;
					border: 0px !important;
				}
			}
			.form-group {
				margin-bottom: 0px;
			}
		}
	}
	.paperclip-image {
		button {
			height: auto;
			width: auto;
			padding: 0px;
			min-width: 0px;
			border: 0px solid $gray !important;
			box-shadow: none !important;
			border-radius: 20px !important;
			background-color: transparent !important;
		}
	}
	.container-icon-paperclip {
		display: none;
	}
	.container-image {
		text-align: center;
	}
	.selected-image {
		border-radius: 50px;
		width: 100px;
		height: 100px;
		object-fit: cover;
	}
	.container-datepicker {
		border-radius: 10px !important;
		background-color: lighten($gray,3) !important;
		border: 1px solid darken($gray,20) !important;
		img {
			@include recolor($green);
		}
	}
	.react-datepicker-wrapper {
		margin-top: 5px !important;
	}
	h4.title-establishments {
		color: $green;
		font-family: $font-family-bold;
		font-size: 20px;
		margin: 0px;
		margin-top: 5px;
		text-align: left;
	}
	.btn-add {
		background-color: transparent !important;
		min-width: auto !important;
		width: auto !important;
		padding: 0px !important;
		margin-top: 0px !important;
		img {
			@include recolor($green);
			width: 40px;
			height: 40px;
			object-fit: contain;
		}
	}
	.btn-remove {
		background-color: transparent !important;
		min-width: auto !important;
		width: auto !important;
		padding: 0px !important;
		margin-top: 0px !important;
		img {
			@include recolor($danger);
			width: 40px;
			height: 40px;
			object-fit: contain;
		}
	}
	.container-establishment-item {
		border-bottom: 1px solid darken($gray,20);
		padding-bottom: 10px;
		padding-top: 10px;
		&:last-child {
			border-bottom: 0px;
		}
	}
	.item-establishments {
		margin-top: 15px;
	}
	.container-establishments {
		margin-top: 10px;
		margin-bottom: 10px;
	}
}