// Register

#register {
	padding: 20px;
	h3 {
		text-align: center;
		font-family: $font-family-bold;
		color: $green;
		margin-top: 25px;
	}
	.container-form {
		background-color: $white;
		border-radius: 20px;
		@include shadow2();
		max-width: 550px;
		padding: 40px;
		margin: 0 auto;
		margin-top: 20px;
		&.payment {
			max-width: 800px;
		}
	}
	.select-bank {
		border-radius: 8px !important;
		background-color: lighten($gray,3) !important;
	}
	.container-datepicker {
		border-radius: 8px !important;
		background-color: lighten($gray,3) !important;
		height: 42px;
		border: 1px solid darken($gray,20) !important;
	}
	.data-payment {
		text-align: center;
		font-size: 14px;
		margin-bottom: 3px;
	}
	.label-payment {
		font-family: $font-family-bold;
		text-align: center;
		font-size: 14px;
		margin-top: 20px;
	}
	.container-icon {
		height: 42px;
		overflow: hidden;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: lighten($gray,3);
		border-radius: 8px;
		border-top-right-radius: 0px;
		border-bottom-right-radius: 0px;
		border-right: 0px;
		padding-left: 8px;
		padding-right: 8px;
		img {
			width: 22px;
			height: 22px;
			object-fit: contain;
		}
	}
	.paperclip-image {
		button {
			height: auto;
			width: auto;
			margin-bottom: 20px;
			padding: 0px;
			min-width: 0px;
			border: 0px solid $gray !important;
			box-shadow: none !important;
			border-radius: 20px !important;
		}
	}
	.photo-title {
		color: $green;
		font-family: $font-family-bold;
		text-align: center;
	}
	.container-icon-paperclip {
		display: none;
	}
	.container-image {
		text-align: center;
	}
	.selected-image {
		border-radius: 70px;
		width: 140px;
		height: 140px;
		object-fit: cover;
	}
	.btn-next {
		background-color: $green_light !important;
		border-radius: 15px !important;
		font-size: 20px !important;
		padding: 10px;
		width: 200px;
		text-transform: uppercase;
		margin-top: 30px;
		margin-bottom: 30px;
		&.btn-back {
			background-color: $black !important;
		}
	}
	.form-group-input {
		input {
			background-color: lighten($gray,3) !important;
			border-radius: 8px !important;
		}
	}
	.speciality_select {
		background-color: lighten($gray,3) !important;
		border-radius: 8px !important;
	}
	.container-select {
		p.label {
			font-family: $font-family-bold;
			color: $black;
			font-size: 14px;
			margin-bottom: 4px;
		}
		& > div {
			display: flex;
			align-items: center;
			background-color: lighten($gray,3) !important;
			border: 1px solid darken($gray,20) !important;
			border-radius: 8px !important;
			margin-bottom: 1rem;
			select {
				width: 80px;
				height: 42px !important;
				background-color: lighten($gray,3) !important;
				border-radius: 8px !important;
				border-top-right-radius: 0px !important;
				border-bottom-right-radius: 0px !important;
				border: 0px !important;
				&.document_type {
					width: 60px;
				}
			}
			.form-group-input {
				flex: 1;
				input {
					border-top-left-radius: 0px !important;
					border-bottom-left-radius: 0px !important;
					border: 0px !important;
				}
			}
			.form-group {
				margin-bottom: 0px;
			}
		}
	}
	.back-container {
		img {
			width: 40px;
			height: 40px;
			object-fit: contain;
		}
		.logo {
			width: 65px;
			height: 65px;
			object-fit: contain;
			margin-left: 15px;
		}
	}
	.tab-container {
		display: flex;
		justify-content: center;
		margin-top: 20px;
	}
	.tab {
		background-color: darken($gray,10);
		border-radius: 30px;
		width: 200px;
		height: 20px;
		margin-left: 10px;
		margin-right: 10px;
		&.active {
			background-color: $green_light;
		}
	}
	.row-buttons {
		margin: 0 auto;
		max-width: 500px;
	}
	.container-plans {
		display: flex;
		margin-bottom: 20px;
		.item-plan {
			border-radius: 10px;
			border: 2px solid $gray;
			display: flex;
			flex: 1;
			margin-right: 10px;
			margin-left: 10px;
			cursor: pointer;
			overflow: hidden;
			.container-image {
				padding: 10px;
				background-color: $gray;
				img {
					width: 50px;
					height: 50px;
					object-fit: contain;
				}
			}
			p {
				margin: 0px;
				padding-left: 10px;
				padding-right: 10px;
				font-size: 16px;
				&.bold {
					font-family: $font-family-bold;
					font-size: 20px;
				}
			}
			.container-data {
				display: flex;
				flex-direction: column;
				justify-content: center;
			}
			&.active {
				border-color: $green !important;
				.container-image {
					background-color: $green;
					img {
						@include recolor($white);
					}
				}
			}
		}
	}
	.container-payment-methods {
		display: flex;
		.item-payment-method {
			border-radius: 10px;
			border: 2px solid $gray;
			display: flex;
			flex: 1;
			margin-right: 10px;
			margin-left: 10px;
			cursor: pointer;
			overflow: hidden;
			margin-bottom: 15px;
			.container-image {
				padding: 10px;
				img {
					width: 30px;
					height: 30px;
					object-fit: contain;
					
				}
			}
			p {
				margin: 0px;
				padding-left: 10px;
				padding-right: 10px;
				font-size: 16px;
				&.bold {
					font-family: $font-family-bold;
					font-size: 20px;
				}
			}
			.container-data {
				display: flex;
				flex-direction: column;
				justify-content: center;
			}
			&.active {
				border-color: $green !important;
			}
		}
	}
	.subtitle-plan {
		text-align: center;
		font-size: 14px;
		margin-top: 15px;
		margin-bottom: 25px;
		&:first-child {
			margin-top: 0px;
		}
	}
	.schedule-title {
		font-family: $font-family-bold;
		font-size: 16px;
		margin-bottom: 10px;
	}
	.container-datepicker {
		border-radius: 10px !important;
		background-color: lighten($gray,3) !important;
		border: 1px solid darken($gray,20) !important;
		img {
			@include recolor($green);
		}
	}
	.row-schedule {
		.react-datepicker-wrapper {
			margin-top: 5px !important;
		}
	}
	h4 {
		color: $green;
		font-family: $font-family-bold;
		font-size: 20px;
		margin: 0px;
		margin-top: 5px;
		text-align: left;
	}
	.btn-add {
		background-color: transparent !important;
		min-width: auto !important;
		padding: 0px !important;
		img {
			@include recolor($green);
			width: 40px;
			height: 40px;
			object-fit: contain;
		}
	}
	.btn-remove {
		background-color: transparent !important;
		min-width: auto !important;
		padding: 0px !important;
		img {
			@include recolor($danger);
			width: 40px;
			height: 40px;
			object-fit: contain;
		}
	}
	.item-establishments {
		margin-top: 15px;
	}
	.container-establishment-item {
		border-bottom: 1px solid darken($gray,20);
		padding-bottom: 10px;
		padding-top: 10px;
		&:last-child {
			border-bottom: 0px;
		}
	}
	.container-establishments {
		margin-top: 10px;
		margin-bottom: 10px;
	}
	.row-payment {
		select {
			border-radius: 10px !important;
			border: 1px solid darken($gray,20) !important;
			background-color: lighten($gray,3) !important;
		}
	}
}

#select-level {
	padding: 20px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
	.container-form {
		background-color: $white;
		display: flex;
		justify-content: center;
		flex-direction: column;
		max-width: 450px;
		width: 90%;
		@include shadow2();
		border-radius: 35px;
		padding: 0px;
		padding-right: 10px;
		padding-left: 10px;
		position: relative;
		padding-top: 70px;
		@media (max-width: 600px) {
			padding-right: 0px;
			padding-left: 0px;
		}
		.container-logo {
			padding: 20px;
			padding-top: 0px;
			text-align: center;
			position: absolute;
			top: -100px;
			width: 100%;
			z-index: 1;
			img {
				width: 100%;
				max-width: 170px;
				margin: 0 auto;
			}
		}
	}
	.arrow-left {
		width: 40px;
		text-align: left;
		margin-top: -10px;
		margin-bottom: 20px;
		margin-left: 30px;
		margin-right: 30px;
		z-index: 2;
		position: relative;
	}
	.row {
		width: 100%;
		margin: 0 auto;
	}
	.item-level {
		border: 2px solid darken($gray,20);
		margin-bottom: 20px;
		border-radius: 10px;
		overflow: hidden;
		cursor: pointer;
		.container-icon {
			background-color: $green_light;
			padding: 20px;
			display: flex;
			justify-content: center;
			align-items: center;
			img {
				@include recolor($white);
			}
		}
		p {
			margin: 0px;
			font-family: $font-family-bold;
			font-size: 24px;
			padding: 20px;
			text-align: center;
		}
	}
}