// Orders

#admin-orders {
	padding: 20px;
	.btn-clean {
		margin-top: 30px;
	}
	input, select {
		background-color: $white !important;
		border-radius: 10px !important;
	}
	.container-datepicker {
		margin-top: 6.5px;
		border: 1px solid darken($gray,20) !important;
	}
	.status {
		font-family: $font-family-bold;
	}
	td, th {
		font-size: 13px;
	}
}

.modal-view-order {
	@include modal();
	.modal-dialog {
		max-width: 500px;
	}
	.modal-body {
		border-radius: 10px !important;
	}
	.modal-header {
		display: none;
	}
}

#modal-view-order {
	button[type="button"] {
		background-color: $black !important;
		border-radius: 10px !important;
		color: $white !important;
		text-transform: uppercase;
		font-family: $font-family-bold;
		font-size: 25px;
		width: 100%;
	}
	p {
		margin: 0px;
		padding: 15px;
		padding-bottom: 10px;
		padding-top: 10px;
		border-bottom: 1px solid darken($gray,20);
	}
	strong {
		font-weight: normal;
		font-family: $font-family-bold;
		color: $blue;
	}
	.row-buttons {
		padding: 15px;
	}
}

.modal-view-details {
	@include modal();
	.modal-dialog {
		max-width: 700px;
	}
	.modal-body {
		border-radius: 10px !important;
	}
	.modal-header {
		display: none;
	}
}

#modal-view-details {
	button[type="button"] {
		background-color: $black !important;
		border-radius: 10px !important;
		color: $white !important;
		text-transform: uppercase;
		font-family: $font-family-bold;
		font-size: 25px;
		width: 100%;
	}
	button.btn-print {
		background-color: $yellow !important;
	}
	p {
		margin: 0px;
		padding: 15px;
		padding-bottom: 3px;
		padding-top: 3px;
	}
	strong {
		font-weight: normal;
		font-family: $font-family-bold;
		color: $blue;
	}
	.btn-close {
		max-width: 300px;
		margin-top: 20px;
		margin-bottom: 15px;
	}
	.container-details {
		padding: 10px;
		padding-bottom: 0px;
	}
	h3 {
		font-family: $font-family-bold;
		font-size: 18px;
		margin: 0px;
		padding: 15px;
		padding-bottom: 3px;
		padding-top: 3px;
		margin-top: 20px;
		margin-bottom: 10px;
		color: $blue;
	}
	.title-measure {
		margin-top: 10px;
	}
}